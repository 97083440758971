import type {GatsbyBrowser} from 'gatsby';

declare global {
  interface Window {
    CallTrk?: {
      swap: () => void;
    };
  }
}

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = (
  _,
  options,
) => {
  if (typeof options.src !== 'string') {
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = options.src;

  document.body.appendChild(script);
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  try {
    if (typeof window.CallTrk !== 'undefined') {
      window.CallTrk.swap();
    }
  } catch (e) {
    // CallRail's code may fail, but...
    // we can't act on its errors
    // we don't want it interfering with other code
    // we don't need the noise in RUM
  }
};
